<template>
  <div>
    <div class="maintitle">{{$route.meta.title}}</div>
    <el-divider></el-divider>
    <el-form :model="pictureinfo" :label-width="labelWidth" v-loading="loading">
      <el-form-item label="公司简称" class="text-left">
        <el-input v-model="pictureinfo.company" size="mini" style="width: 300px" placeholder="请输入公司简称" :maxlength="textlength.company" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="角标标语" class="text-left">
        <el-tag :key="tag" v-for="tag in pictureinfo.corner" closable :disable-transitions="false" @close="handleCornerClose(tag)" class="margin-right">
          {{tag}}
        </el-tag>
        <el-input class="input-new-tag" v-if="inputCornerVisible" style="width:300px" v-model="inputCornerValue" ref="saveCornerTagInput" size="small" @keyup.enter.native="handleInputCornerConfirm" @blur="handleInputCornerConfirm" :maxlength="textlength.corner" show-word-limit placeholder="请输入角标内容，必须为4个字">
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showCornerInput">添加</el-button>
      </el-form-item>
      <el-form-item label="创意描述1" class="text-left">
        <el-tag :key="tag" v-for="tag in pictureinfo.diy1" closable :disable-transitions="false" @close="handleDiy1Close(tag)" class="margin-right">
          {{tag}}
        </el-tag>
        <el-input class="input-new-tag" v-if="inputDiy1Visible" style="width:300px" v-model="inputDiy1Value" ref="saveDiy1TagInput" size="small" @keyup.enter.native="handleInputDiy1Confirm" @blur="handleInputDiy1Confirm" :maxlength="textlength.diy1" show-word-limit placeholder="请输入创意描述，最多8个字符">
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showDiy1Input">添加</el-button>
      </el-form-item>
      <el-form-item label="创意描述2" class="text-left">
        <el-tag :key="tag" v-for="tag in pictureinfo.diy2" closable :disable-transitions="false" @close="handleDiy2Close(tag)" class="margin-right">
          {{tag}}
        </el-tag>
        <el-input class="input-new-tag" v-if="inputDiy2Visible" style="width:300px" v-model="inputDiy2Value" ref="saveDiy2TagInput" size="small" @keyup.enter.native="handleInputDiy2Confirm" @blur="handleInputDiy2Confirm" :maxlength="textlength.diy2" show-word-limit placeholder="请输入创意描述，最多8个字符">
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showDiy2Input">添加</el-button>
      </el-form-item>
      <el-form-item label="母版图片" class="text-left">
        <span style="margin-right: 10px; color: red">图片大小20kb~4000kb,图片格式：JPG</span>
        <span style="margin-right: 10px; color: red">（共：{{ this.pictureinfo.pic.length }}张）</span>
        <el-button type="danger" @click="clearUploadDir('image')" size="mini">清空图片</el-button>
        <el-upload multiple :action="uploadurl" name="image" :data="uploadData" :auto-upload="true" :on-success="showRes" :show-file-list="false" :before-upload='showloading'>
          <el-button type="primary" plain size="mini">
            <i class="el-icon-upload el-icon--rihgt"></i>点击选择图片
          </el-button>
        </el-upload>
        <div v-if="this.pictureinfo.pic.length > 0" class="piclistDiv">
          <li v-for="(item, index) in this.pictureinfo.pic" :index="index" :key="index">
            <img :src="picHOST+item" alt="" />
            <div class="del">
              <i class="el-icon-delete del" @click="delPic(item,index)"></i>
              <i class="el-icon-search search" @click="showPic(item)"></i>
            </div>
          </li>
        </div>
      </el-form-item>
      <el-form-item label="选择模板" class="text-left">
        <el-tag :key="tag.id" v-for="tag in pictureinfo.template" closable :disable-transitions="false" @close="closeTemplate(tag)" class="margin-right">
          {{tag.name}}
        </el-tag>
        <el-button size="mini" @click="templateDiv=true">选择</el-button>
      </el-form-item>
      <el-form-item label="是否下载" class="text-left">
        <el-radio-group v-model="pictureinfo.isDownload" @change="getDatum">
          <el-radio :label="1">下载</el-radio>
          <el-radio :label="2">直接发布</el-radio>
          <el-radio :label="3">发布并下载</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="选择发布" v-if="pictureinfo.isDownload>1" class="text-left">
        <el-select v-model="pictureinfo.datum" placeholder="请选择信息">
          <el-option v-for="(item,index) in datumlist" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-button size="mini" type="success" @click="submit">提交</el-button>
    </el-form>
    <el-dialog title="模板选择" :visible.sync="templateDiv" :close-on-click-modal="false" @closed="resetSlct">
      <el-select size="mini" placeholder="请选择模板类型" @change="getTemplate" v-model="slctType" class="margin-bottom">
        <el-option v-for="(item,index) in templateTypeList" :key="index" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-button size="mini" @click="resetSlct" class="margin-left">重置</el-button>
      <el-table :data="templateList" border @selection-change="handleSelectionChange" ref="templateSelection">
        <el-table-column type="selection" align="center" width="55"></el-table-column>
        <el-table-column prop="name" label="模板名称"></el-table-column>
        <el-table-column label="">
          <template slot-scope="scope">
            <img :src="picHOST+scope.row.pic" alt="" style="height:40px" @click="showPic(picHOST+scope.row.pic)">
          </template>
        </el-table-column>
      </el-table>
      <el-dialog :visible.sync="showPicDiv" width="790px" append-to-body>
        <img :src="crntPic" alt="" style="width:750px;">
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="templateDiv = false">取 消</el-button>
        <el-button type="primary" @click="setTemplate">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
export default {
  data() {
    return {
      textlength: [],
      loading: false,
      pictureinfo: {
        company: '',
        corner: [],
        diy1: [],
        diy2: [],
        pic: [],
        template: [],
        datum: '',
        isDownload: 1
      },
      labelWidth: '120px',
      inputCornerVisible: false,
      inputCornerValue: '',
      inputDiy1Visible: false,
      inputDiy1Value: '',
      inputDiy2Visible: false,
      inputDiy2Value: '',
      datumlist: [],
      templateList: [],
      templateDiv: false,
      picHOST: window.domain,
      templateTypeList: [],
      slctType: '',
      slctTemplate: [],
      showPicDiv: false,
      crntPic: '',
      uploadurl:this.HOST + "/picture/upload",
      uploadData:{
        ziaccounttoken: sessionStorage.getItem("ziaccounttoken"),
        ziaccountuuid: sessionStorage.getItem("ziaccountuuid"),
      },
    }
  },
  methods: {
    handleSelectionChange(value) {
      this.slctTemplate = value;
    },
    handleCornerClose(tag) {
      this.pictureinfo.corner.splice(this.pictureinfo.corner.indexOf(tag), 1);
    },
    handleDiy1Close(tag) {
      this.pictureinfo.diy1.splice(this.pictureinfo.diy1.indexOf(tag), 1);
    },
    handleDiy2Close(tag) {
      this.pictureinfo.diy2.splice(this.pictureinfo.diy2.indexOf(tag), 1);
    },
    showCornerInput() {
      this.inputCornerVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveCornerTagInput.$refs.input.focus();
      });
    },
    handleInputCornerConfirm() {
      let inputValue = this.inputCornerValue;
      if (inputValue) {
        if (inputValue.length != 4) {
          this.$message({
            type: 'error',
            message: '角标标语必须是4个字'
          })
          return;
        }
        this.pictureinfo.corner.push(inputValue);
      }
      this.inputCornerVisible = false;
      this.inputCornerValue = '';
    },
    showDiy1Input() {
      this.inputDiy1Visible = true;
      this.$nextTick(_ => {
        this.$refs.saveDiy1TagInput.$refs.input.focus();
      });
    },
    handleInputDiy1Confirm() {
      let inputValue = this.inputDiy1Value;
      if (inputValue) {
        this.pictureinfo.diy1.push(inputValue);
      }
      this.inputDiy1Visible = false;
      this.inputDiy1Value = '';
    },
    showDiy2Input() {
      this.inputDiy2Visible = true;
      this.$nextTick(_ => {
        this.$refs.saveDiy2TagInput.$refs.input.focus();
      });
    },
    handleInputDiy2Confirm() {
      let inputValue = this.inputDiy2Value;
      if (inputValue) {
        this.pictureinfo.diy2.push(inputValue);
      }
      this.inputDiy2Visible = false;
      this.inputDiy2Value = '';
    },
    showCornerInput() {
      this.inputCornerVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveCornerTagInput.$refs.input.focus();
      });
    },
    handleInputCornerConfirm() {
      let inputValue = this.inputCornerValue;
      if (inputValue) {
        if (inputValue.length != 4) {
          this.$message({
            type: 'error',
            message: '角标标语必须是4个字'
          })
          return;
        }
        this.pictureinfo.corner.push(inputValue);
      }
      this.inputCornerVisible = false;
      this.inputCornerValue = '';
    },
    getDatum(value) {
      if (value>1 && this.datumlist.length == 0) {
        this.$axios({
          url: this.HOST + '/picture/getDatumList',
          method: 'post',
          data: {}
        }).then(res => {
          if (res.data.status == 1) {
            this.datumlist = res.data.list
          }
        })
      }
    },
    getTemplate() {
      this.$axios({
        url: this.HOST + '/picture/getTemplateList',
        method: 'post',
        data: {
          type: this.slctType
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.templateList = res.data.list
        }
      })
    },
    getTemplateType() {
      console.log('getTemplateType');
      if (this.templateTypeList.length == 0) {
        this.$axios({
          url: this.HOST + '/picture/getTemplateType',
          method: 'post',
          data: {}
        }).then(res => {
          if (res.data.status == 1) {
            this.templateTypeList = res.data.list
          }
        })
      }
    },
    setTemplate() {
      // this.templateName = [];
      // for (let item in value) {
      //   this.templateName.push(value[item].name)
      // }
      // console.log(this.pictureinfo);
      this.pictureinfo.template = this.slctTemplate;
      this.templateDiv = false;
    },
    resetSlct() {
      this.$refs.templateSelection.clearSelection();
      this.slctTemplate = []
    },
    showPic(pic) {
      console.log(pic)
      this.crntPic = pic;
      this.showPicDiv = true;
    },
    closeTemplate(tag){
      this.pictureinfo.template.splice(this.pictureinfo.template.indexOf(tag), 1);
    },
    //图片上传结果提示
    showRes(res) {
      this.loading = false;
      if (res.status == 1) {
        this.pictureinfo.pic.push(res.file)
      } else {
        this.$message({
          type: "error",
          message: res.info,
        });
      }
    },
    showloading() {
      this.loading = true;
    },
    delPic(pic,index){
      this.$axios({
        url:this.HOST+'/picture/delPic',
        method:'post',
        data:{
          pic:pic
        }
      }).then(res=>{
        if(res.data.status == 1){
          this.pictureinfo.pic.splice(index,1);
        }
      })
    },
    clearUploadDir(){
      this.$axios({
        url:this.HOST+'/picture/delAllPic',
        method:'post',
        data:{}
      }).then(res=>{
        if(res.data.status == 1){
          this.pictureinfo.pic = [];
        }
      })
    },
    submit(){
      this.loading = true;
      this.hasPic = 0;
      this.$axios({
        url:this.HOST+'/picture/submitInfo',
        method:'post',
        data:{
          info:this.pictureinfo
        }
      }).then(res=>{
        this.loading = false;
        if(res.data.status == 1){
          this.$message({
            type:'success',
            message:res.data.info
          })
          if(res.data.file){
            window.open(this.picHOST+res.data.file)
          }
        }
      })
    }
  },
  created() {
    this.$axios({
      url: this.HOST + '/picture/getTextLength',
      method: 'post',
      data: {}
    }).then(res => {
      if (res.data.status == 1) {
        this.textlength = res.data.textlength
        this.templateTypeList = res.data.templatetypelist
        if(res.data.hasPic){
          this.hasPic=res.data.hasPic
        }
      }
    })
  }
}
</script>