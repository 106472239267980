<template>
  <div>
    <div class="maintitle">商品列表：{{ datumName }}</div>
    <el-divider></el-divider>
    <el-table :data="list" border v-loading="loading" element-loading-text="商品发布中...">
      <el-table-column prop="id" label="商品id" width="100"></el-table-column>
      <el-table-column prop="fullname" label="商品标题"></el-table-column>
      <el-table-column prop="time" label="预计发布时间" align="center" width='200'>
        <template slot-scope="scope">
          <span @click="mdfyTime(scope)" class='cursor-pointer'>{{scope.row.time}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="realtime" label="实际发布时间" align="center" width='200'></el-table-column>
      <el-table-column label="状态" width="100" prop="status_text"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="preview(scope)">预览</el-button>
          <el-button type="text" @click="publish(scope,1)" v-if="scope.row.status == 1">发布</el-button>
          <el-button type="text" @click="publish(scope,2)" v-if="scope.row.status == 1">保存到商家后台草稿箱</el-button>
          <el-button type="text" @click="delbtn(scope)" v-if="scope.row.status == 1">删除</el-button>
          <el-button type="text" @click="mdfy(scope)" v-if="scope.row.status == 1">修改标题</el-button>
          <el-button type="text" @click="showReason(scope)" v-if="scope.row.status == 4">失败原因</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog width="750px" :title="goodsinfo.fullname" :visible.sync="dialogGoods">
      <div class="top">
        <div class="lunbo">
          <div class="block">
            <el-carousel height="150px">
              <el-carousel-item v-for="(item, index) in goodsinfo.canonicalImage" :key="index">
                <img :src="item.url" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="lunbo" v-if="goodsinfo.canonicalVideo">
          <video :src="goodsinfo.canonicalVideo[0].url" controls></video>
        </div>
      </div>
      <div class="meta">
        <div class="address">
          发货地：{{
            goodsinfo.foundingLocation.province
          }}{{ goodsinfo.foundingLocation.city }}
        </div>
        <div class="address">
          <div v-for="(item, index) in goodsinfo.sellingInfo.priceSpecification" :key="index">
            <div v-if="item.startQuantity">
              起批：{{ item.startQuantity }} 价格：{{ item.price }}单位：{{ goodsinfo.sellingInfo.unit }}
            </div>
          </div>
        </div>
        <div class="canshu">
          <div v-for="(item, index) in goodsinfo.meta" :key="index">
            <span>{{ item.name }}</span>：
            <span>{{ item.value }}</span>
          </div>
        </div>
      </div>
      <div class="dialogMain" v-html="goodsinfo.detail"></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogGoods = false">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="addDiv" :close-on-click-modal="false">
      <el-input v-model="newtitle" auto-complete="off" placeholder="请输入标题"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDiv = false">取 消</el-button>
        <el-button type="primary" @click="submdfy">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="mdfyTimeDiv" title='修改发布时间' :close-on-click-modal="false">
      <el-date-picker v-model="newTime" type="datetime" placeholder="选择发布时间"></el-date-picker>
      <div slot="footer" class="dialog-footer">
        <el-button @click="mdfyTimeDiv = false">取 消</el-button>
        <el-button type="primary" @click="subMdfyTime">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //资料列表
      datumName: "",
      picHOST: window.domain,
      goodsinfo: {
        foundingLocation: {
          city: "",
          province: "",
        },
        sellingInfo: {
          priceSpecification: [
            {
              price: "",
              startQuantity: "",
            },
          ],
          unit: "",
        },
      },
      dialogGoods: false,
      loading: false,
      addDiv: false,
      crntscope: "",
      newtitle: "",
      mdfyTimeDiv: false,
      newTime: "",
    };
  },
  methods: {
    // 获取资料列表，注意通过商户id获取对应资料，该设置尚未处理。
    getlist() {
      let id = this.$route.params.id;
      this.$axios({
        url: this.HOST + "/goods/goodslist",
        method: "post",
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.list;
          // this.list = res.data.list.data;
          // this.total = res.data.list.total;
          this.datumName = res.data.datumName;
        }
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      // this.getlist();
    },
    showReason(scope) {
      if (scope.row.failreason) {
        this.$alert(scope.row.failreason, "发送失败原因", {
          confirmButtonText: "确定",
        });
      }
    },
    //修改发布时间
    mdfyTime(scope) {
      this.crntscope = scope;
      this.newTime = scope.row.time;
      this.mdfyTimeDiv = true;
    },
    //提交修改
    subMdfyTime() {
      this.$axios({
        url: this.HOST + '/goods/mdfyTime',
        method: 'post',
        data: {
          id: this.crntscope.row.id,
          time: this.newTime
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.crntscope.row.time = res.data.time;
          this.mdfyTimeDiv = false;
        }
      });
    },
    // 预览资料
    preview(scope) {
      if (sessionStorage.getItem('third_party') == 1) {
        this.$axios({
          url:this.HOST+'/goods/previewGoodsFromBaidu',
          method:'post',
          data:{
            id: scope.row.id
          }
        }).then(res=>{
          if(res.data.status == 1){
            window.open(res.data.url)
          }
        })
      } else {
        this.$axios({
          url: this.HOST + "/goods/previewGoods",
          method: "post",
          data: {
            id: scope.row.id,
            status: scope.row.status,
          },
        })
          .then((res) => {
            if (res.data.status == 1) {
              this.goodsinfo = res.data.detail.detail;
              this.dialogGoods = true;
            } else {
              this.$message.error(res.data.info);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    mdfy(scope) {
      this.crntscope = scope;
      this.newtitle = scope.row.fullname;
      this.addDiv = true;
    },
    submdfy() {
      this.$confirm("确定修改标题吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios({
          url: this.HOST + "/goods/mdfyTitle",
          method: "post",
          data: {
            id: this.crntscope.row.id,
            fullname: this.newtitle,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.$message.success(res.data.info);
            this.crntscope.row.fullname = this.newtitle;
            this.addDiv = false;
          }
        });
      });
    },
    // 发布商品
    publish(scope, status) {
      this.$confirm("确定发布吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.$axios({
            url: this.HOST + "/goods/publish",
            method: "post",
            data: {
              id: scope.row.id,
              aid: scope.row.aid,
              status: status
            },
          })
            .then((res) => {
              this.loading = false;
              if (res.data.status == 1) {
                this.$message.success(res.data.info);
              } else {
                this.$message.error(res.data.info);
              }
              this.getlist();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 删除商品
    delbtn(scope) {
      this.$confirm("确定删除该商品吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: this.HOST + "/goods/delGoods",
            method: "post",
            data: {
              id: scope.row.id,
            },
          })
            .then((res) => {
              if (res.data.status == 1) {
                this.$message.success(res.data.info);
              } else {
                this.$message.error(res.data.info);
              }
              this.getlist();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
  created: function () {
    this.getlist();
  },
};
</script>

<style>
.el-carousel__item,
.el-carousel__container {
  height: 300px !important;
}

.top {
  display: flex;
  justify-content: space-between;
  min-width: 700px;
}

.lunbo {
  width: 300px;
}

.lunbo img {
  width: 300px;
  height: 300px;
}

.lunbo video {
  width: 300px;
  height: 300px;
}

.meta {
  padding: 20px 0;
}

.meta .address {
  padding: 10px 20px;
  text-align: left;
}

.meta .canshu {
  display: flex;
  flex-wrap: wrap;
}

.meta .canshu div {
  padding: 20px;
}

.dialogMain p {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}

i {
  cursor: pointer;
}
</style>
