<template>
  <div>
    <div class="maintitle">{{ruleForm.id==''?'添加':'修改'}}危化品信息</div>
    <el-divider>必填信息</el-divider>
    <el-form :model="ruleForm" :label-width="labelWidth" v-loading="loading">
      <el-form-item label="产品名称" class="text-left">
        <el-input size="mini" v-model="ruleForm.name" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="备注" class="text-left">
        <el-input size="mini" v-model="ruleForm.beizhu" style="width: 300px" placeholder="请输入信息备注，非必填"></el-input>
      </el-form-item>
      <el-form-item label='报价方式' :label-width="labelWidth" class="text-left">
        <el-radio-group size="mini" v-model="ruleForm.quotationType">
          <el-radio :label="1">按产品数量报价</el-radio>
          <el-radio :label="0">按产品规格报价</el-radio>
          <el-radio :label="2">面议</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="售卖价格" :label-width="labelWidth" class="text-left" v-if='ruleForm.quotationType == 1'>
        <div class="qujian">
          <div>
            <div>起订量</div>
            <div>价格</div>
          </div>
          <div v-for="(item, index) in pricearr" :key="index">
            <div>
              <span>≧</span>
              <el-input size="mini" v-model="item.startQuantity" placeholder="请输入起订量"></el-input>
            </div>
            <div>
              <span>¥</span>
              <el-input size="mini" v-model="item.price" placeholder="请输入价格"></el-input>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="规格" :label-width="labelWidth" class="text-left" v-if="ruleForm.quotationType==0">
        <div v-for="(item,index) in ruleForm.detail.specdigest" :key="index" style="width:300px;background:#F8F8F8;padding:10px;margin-bottom:10px;">
          <el-input size="mini" v-model="item.name" placeholder="请输入商品规格，最多添加两个商品规格" class="margin-bottom">
            <template v-if="item.unit!=''" slot="append">
              <el-button size="mini" @click="delSpecItem(index)" v-if='ruleForm.detail.specdigest.length>1'>删除</el-button>
            </template>
          </el-input>
          <div style="padding-left:20px;">
            <div v-for="(i,x) in item.values" :key="x">
              <el-input size="mini" v-model="i.value" @keyup.enter.native="checkSpec(index,x)" placeholder="请输入商品规格值，最多添加20个">
                <template v-if="item.unit!=''" slot="append">
                  <el-button size="mini" @click="delSpecValue(index,x)" v-if='item.values.length>1'>删除</el-button>
                </template>
              </el-input>
            </div>
          </div>
        </div>
        <div>
          <el-button size="mini" @click='addSpec' v-if='ruleForm.detail.specdigest.length<2'>添加商品规格</el-button>
        </div>
      </el-form-item>
      <el-form-item label="商品价格" :label-width="labelWidth" class="text-left" v-if='ruleForm.quotationType==0'>
        <el-table :data="specPriceList">
          <el-table-column prop="spec" width="200px" label="商品规格"></el-table-column>
          <el-table-column prop="value" label="规格值" width="200px"></el-table-column>
          <el-table-column label="商品价格" width="200px" v-if="ruleForm.quotationType==0">
            <template slot-scope="scope">
              <el-input size="mini" v-model='scope.row.price' placeholder="请输入商品价格" type='number'></el-input>
            </template>
          </el-table-column>
          <el-table-column label="商品库存" width="100px">
            <template slot-scope="scope">
              <el-input size="mini" v-model='scope.row.stock' type='number'></el-input>
            </template>
          </el-table-column>
          <el-table-column label="规格图片">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="selectSpecImage(scope.$index)">选择图片</el-button>
              <el-button size="mini" @click="delSpecPic(scope)" class="margin-left" v-if="scope.row.image != ''">删除图片</el-button>
              <img :src="scope.row.image" v-if="scope.row.image != ''" style="height:40px; float:left;margin-right:20px" />
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="修饰词行业" class="text-left">
        <el-select size="mini" v-model="ruleForm.hangye" placeholder="请选择修饰词行业" filterable default-first-option @change='getTemplate'>
          <el-option v-for="item in hangyes" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分类" class="text-left">
        <el-input size="mini" v-model="ruleForm.classify" style="margin-right: 10px; width: 500px"></el-input>
        <el-button size="mini" type="primary" @click="classifyAllBtn">
          <i class="el-icon-s-unfold"></i> 选择分类
        </el-button>
      </el-form-item>
      <el-form-item label="单位" class="text-left">
        <el-select size="mini" filterable allow-create v-model="ruleForm.unit" placeholder="请选择或输入单位" default-first-option>
          <el-option v-for="(item,index) in unitlist" :key="index" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="hidden">
        <el-input size="mini" type="hidden" v-model="ruleForm.categoryId" style="margin-right: 10px; width: 500px"></el-input>
      </el-form-item>
      <el-form-item label="标题关键词" class="text-left">
        <el-row :gutter="10">
          <el-col :span="8">
            <span style="margin-right: 10px; color: red">
              标题调用关键词1</span>
            <div>
              <el-input type="textarea" v-model="ruleForm.detail.keywords" style="width: 100%" rows="10" placeholder="请输入关键词一行一个，注意不要使用特殊符号"></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <span style="margin-right: 10px; color: red">
              标题调用关键词2</span>
            <div>
              <el-input type="textarea" v-model="ruleForm.detail.keywords2" style="width: 100%" rows="10" placeholder="请输入关键词一行一个，注意不要使用特殊符号"></el-input>
            </div>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="商品关键词" class="text-left">
        <el-row :gutter="10">
          <el-col :span="8">
            <span style="margin-right: 10px; color: red">
              标题下方商品关键词调用1</span>
            <div>
              <el-input type="textarea" v-model="ruleForm.detail.tags" style="width: 100%" rows="10" placeholder="随机调用您填入的关键词，展现在标题下方的五个关键词位置，请围绕商品特征填写，描述越准确，商品被展现的机会越多，每行一个关键词"></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <span style="margin-right: 10px; color: red">
              标题下方商品关键词调用2</span>
            <div>
              <el-input type="textarea" v-model="ruleForm.detail.tags2" style="width: 100%" rows="10" placeholder="随机调用您填入的关键词，展现在标题下方的五个关键词位置，请围绕商品特征填写，描述越准确，商品被展现的机会越多，每行一个关键词"></el-input>
            </div>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="轮播首图" class="text-left">
        <span style="margin-right: 10px; color: red">图片大小20kb~4000kb,图片格式：JPG</span>
        <span style="margin-right: 10px; color: red">（共：{{ this.ruleForm.detail.firstimage.length }}张）</span>
        <el-button size="mini" type="danger" @click="clearUploadDir('firstimage')">清空轮播首图</el-button>
        <el-button size="mini" type="success" @click="selectFirstImage()">选择图片</el-button>
        <div v-if="ruleForm.detail.firstimage.length > 0" class="piclistDiv">
          <li v-for="(item, index) in ruleForm.detail.firstimage" :index="index" :key="index">
            <img :src="item" alt="" />
            <div class="del">
              <i class="el-icon-delete del" @click="delPic(item, 'firstimage')"></i>
              <i class="el-icon-search search" @click="showPic(item, 'image')"></i>
            </div>
          </li>
        </div>
      </el-form-item>
      <el-form-item label="轮播图" class="text-left">
        <span style="margin-right: 10px; color: red">图片大小20kb~4000kb,图片格式：JPG</span>
        <span style="margin-right: 10px; color: red">（共：{{ this.ruleForm.detail.image.length }}张）</span>
        <el-button size="mini" type="danger" @click="clearUploadDir('image')">清空轮播图</el-button>
        <el-button size="mini" type="success" @click="selectBanner()">选择图片</el-button>
        <div v-if="ruleForm.detail.image.length > 0" class="piclistDiv">
          <li v-for="(item, index) in ruleForm.detail.image" :index="index" :key="index">
            <img :src="item" alt="" />
            <div class="del">
              <i class="el-icon-delete del" @click="delPic(item, 'image')"></i>
              <i class="el-icon-search search" @click="showPic(item, 'image')"></i>
            </div>
          </li>
        </div>
      </el-form-item>
      <el-form-item label='详情是否使用轮播图' :label-width="labelWidth" class="text-left">
        <el-radio-group size="mini" v-model="ruleForm.useimage">
          <el-radio :label="1">使用</el-radio>
          <el-radio :label="0">不使用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="详情图上传" class="text-left">
        <span style="margin-right: 10px; color: red">
          图片大小20kb~4000kb,图片格式：JPG</span>
        <el-button size="mini" type="danger" @click="clearUploadDir('infoimg')">清空详情图</el-button>
        <el-button size="mini" type="success" @click="selectInfoImage()">选择详情图</el-button>
        <div v-if="ruleForm.detail.infoimg.length > 0" class="piclistDiv">
          <li v-for="(item, index) in ruleForm.detail.infoimg" :index="index" :key="index">
            <img :src="item" alt="" />
            <div class="del">
              <i class="el-icon-delete del" @click="delPic(item, 'infoimage')"></i>
              <i class="el-icon-search search" @click="showPic(item, 'image')"></i>
            </div>
          </li>
        </div>
      </el-form-item>
      <el-form-item label="视频上传" class="text-left">
        <span style="margin-right: 10px; color: red">
          视频比例16：9或1：1，支持 mp4
          格式。建议时长9~30秒，视频文件大小不超过10M</span>
        <span style="margin-right: 10px; color: red">（共：{{ this.ruleForm.detail.video.length }}条）</span>
        <el-button size="mini" type="danger" @click="clearUploadDir('video')">清空视频</el-button>
        <el-button size="mini" type="success" @click="selectVideo()">选择视频</el-button>
        <div v-if="ruleForm.detail.video.length > 0" class="piclistDiv videolistDiv">
          <li v-for="(item, index) in ruleForm.detail.video" :index="index" :key="index">
            <img src="../../../public/static/images/videoas.png" alt="" />
            <div class="del">
              <i class="el-icon-delete del" @click="delPic(item, 'video')"></i>
              <i class="el-icon-search search" @click="showPic(item, 'video')"></i>
            </div>
          </li>
        </div>
      </el-form-item>
      <el-form-item label="产品参数" class="text-left">
        <el-button size="mini" type="primary" @click="addParameter">新增参数</el-button>
        <el-button size="mini" type="primary" @click="parameterDialog = true">批量添加</el-button>
        <el-select size="mini" class='margin-left' placeholder="选择参数模板" v-if='templateList.length>0' v-model='crntTemplate'>
          <el-option v-for="(item,index) in templateList" :key='index' :label='item.name' :value='item.id'></el-option>
        </el-select>
        <el-button size="mini" class="margin-left" type='primary' @click="useTemplate" v-if='templateList.length>0 && crntTemplate!=""'>使用参数模板</el-button>
        <div class="ParameterBox">
          <div class="ParameterDiv" v-for="(item, index) in parameters" :key="index">
            <el-input size="mini" v-model="item.name" placeholder="请输入参数名"></el-input>
            <i class="el-icon-minus"></i>
            <el-input size="mini" v-model="item.value" type="textarea" placeholder="请输入参数值，每行填写一个参数"></el-input>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="index>9">
              <i class="el-icon-close" @click.prevent="removeParameter(item)"></i>
            </el-tooltip>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="信息发布数量" class="text-left">
        <el-input size="mini" type="number" v-model="ruleForm.num" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="每天发布条数" class="text-left">
        <el-input size="mini" type="number" v-model="ruleForm.daynum" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="间隔天数" class="text-left">
        <el-input size="mini" type="number" v-model="ruleForm.interval" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="开始发布时间" class="text-left">
        <el-date-picker size="mini" v-model="ruleForm.starttime" type="datetime" placeholder="选择日期时间" :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <!-- <el-divider>非必填信息</el-divider> -->
      <!-- <el-form-item label="品牌" class="text-left">
        <el-input size="mini" v-model="ruleForm.brand" style="width: 300px"></el-input>
      </el-form-item> -->
      <el-form-item label="信息标题" class="text-left">
        <span style="margin-right: 10px; color: red">
          优先使用标题，不够信息发布条数得由关键词随机组成标题</span>
        <div>
          <el-input size="mini" type="textarea" v-model="ruleForm.detail.title" style="width: 500px" rows="8" placeholder="请输入信息标题 一行一个"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="产品发货地" class="text-left">
        <div class="block">
          <el-cascader size="mini" style='width:500px;' v-model="ruleForm.area" placeholder="试试搜索：潍坊" :options="options" collapse-tags :props="props" filterable></el-cascader>
        </div>
      </el-form-item>
      <el-form-item label="产品售卖地区" style="width: 1000px" class="text-left">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全国</el-checkbox>
        <div style="margin: 15px 0"></div>
        <!-- <el-checkbox-group v-model="groupinfo.auth">
            <el-checkbox v-for="(item,index) in menus" :key="index" :label="item.menuid" :value="item.menuid" :class="item.pid==0?'toplevel':'text-info'">{{ item.title }}
            </el-checkbox>
          </el-checkbox-group> -->
        <el-checkbox-group v-model="ruleForm.district" @change="handledistrictChange">
          <el-checkbox v-for="(item, index) in cities" :label="item" :key="index" :value="item">{{ item }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="跳转网站">
        <el-input size="mini" v-model="ruleForm.website" placeholder="请输入产品跳转网页地址"></el-input>
      </el-form-item>
      <el-form-item class="text-left">
        <el-button size="mini" type="primary" @click="submitForm(1)">保存草稿</el-button>
        <el-button size="mini" type="primary" @click="submitForm(4)">提交审核</el-button>
      </el-form-item>
    </el-form>
    <!-- 分类选择 -->
    <el-dialog title="选择分类" :visible.sync="classifyDialog" width="900px" :before-close="checkCategory">
      <div class="classifys">
        <div class="search">
          <el-input size="mini" placeholder="请输入关键词" v-model="searchkey" clearable @keyup.enter.native='getClassifyByKey'></el-input>
          <el-button size="mini" @click="getClassifyByKey">查询</el-button>
        </div>
        <div class="content">{{ ruleForm.classify }}</div>
        <div class="class">
          <el-tabs v-model="activeName" type="card">
            <!-- <el-tabs v-model="activeName" type="card" @tab-click="handleClick"> -->
            <el-tab-pane label="一级分类" name="first" :disabled="firstClassify.length == 0">
              <div class="classStyle">
                <span v-for="item in firstClassify" :key="item.cate_id" @click="classifybtn(item.cate_id, item.name, 1)">{{ item.name }}</span>
              </div>
            </el-tab-pane>
            <el-tab-pane label="二级分类" name="second" :disabled="secondClassify.length == 0">
              <div class="classStyle">
                <span v-for="item in secondClassify" :key="item.cate_id" @click="classifybtn(item.cate_id, item.name, 2)">{{ item.name }}</span>
              </div>
            </el-tab-pane>
            <el-tab-pane label="三级分类" name="third" :disabled="thirdClassify.length == 0">
              <div class="classStyle">
                <span v-for="item in thirdClassify" :key="item.cate_id" @click="classifybtn(item.cate_id, item.name, 3)">{{ item.name }}</span>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <el-dialog width="50%" :visible.sync="classifyInnerVisible" append-to-body>
        <div class="searchResultBox">
          <div v-for="(item, index) in searchResult" :key="index" @click="searchResultBtn(item.cate_id, item.name)">
            {{ item.name }}
          </div>
        </div>
      </el-dialog>
    </el-dialog>
    <!-- 批量添加参数 -->
    <el-dialog title="批量添加参数" :visible.sync="parameterDialog" width="900px">
      <div class="parameters">
        <div>
          <span>参数名</span>
          <el-input size="mini" type="textarea" rows="20" v-model="canshuming"></el-input>
        </div>
        <div>
          <span>参数值</span>
          <el-input size="mini" type="textarea" rows="20" v-model="canshuzhi"></el-input>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="parametersBtn">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 图片、视频预览 -->
    <el-dialog :visible.sync="previewDialog">
      <img v-if="previewType == 'image'" :src="previewUrl" style="width: 100%" />
      <video v-if="previewType == 'video'" :src="previewUrl" style="width: 100%" controls></video>
    </el-dialog>
    <!-- 详情图预览 -->
    <el-dialog :visible.sync="previewInfoImage" width='800px'>
      <img v-for='(item,index) in crntInfoImage' :key='index' :src="item" style='width:750px;display:block' />
    </el-dialog>
    <!-- 文件夹名称 -->
    <el-dialog title="文件夹名称" :visible.sync="pathNameDiv" width="300px" @closed="pathname=''">
      <el-input v-model='pathname' placeholder="请输入文件夹名称"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="pathNameDiv=false">取 消</el-button>
        <el-button size="mini" type="primary" @click="addPath">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择素材 -->
    <el-dialog :title="crnttitle" :visible.sync="materialDiv" width="900px" v-loading="materialloading">
      <div class="path-div margin-bottom">
        <div v-for="(item,index) in path" :key="index">
          <div v-if="index<path.length-1">
            <span class="pathname" @click="showMaterial(fileType,item)">{{ item.name }}</span>
            <span>&nbsp;>&nbsp;</span>
          </div>
          <div v-else>{{ item.name }}</div>
        </div>
        <div class="margin-big-left">
          <el-button size="mini" @click="pathNameDiv=true" type="success">新建文件夹</el-button>
        </div>
      </div>
      <div class="imglist">
        <div v-if="path[path.length-1].id != 0">
          <img src="/static/images/pre.png" alt="">
          <p>返回上一级</p>
          <div class="cover-div" @click="showMaterial(fileType,path[path.length-2])"></div>
        </div>
        <div v-for="(item,index) in materialList" :key="index">
          <img src="/static/images/folder.png" alt="" v-if="item.fileType==1">
          <img :src="item.url" alt="" v-if="item.fileType==2">
          <img src="/static/images/video.png" alt="" v-if="item.fileType==3">
          <img src="/static/images/pdf.png" alt="" v-if="item.fileType==4">
          <p><span>{{ item.fileName }}</span><span v-if="item.fileType!=1">.{{ item.fileExt }}</span></p>
          <div class="cover-div" v-if="item.fileType==1" @click="showMaterial(fileType,item)"></div>
          <div class="cover-div" v-if="item.fileType!=1 && crntparam != 'spec'" @click="changeSelect(item)"></div>
          <div class="cover-div" v-if="crntparam == 'spec'" @click="setSpec(item)"></div>
          <div class="view-div" v-if="item.fileType==2" @click="showMaterailPic(item.url)">预览</div>
          <div class="view-div" v-if="item.fileType==3" @click="showMaterailVideo(item.url)">预览</div>
          <div class="selected" v-if="crntparam != 'spec' && item.fileType==fileType && ruleForm.detail[crntparam].indexOf(item.url) != -1">
            <img src="/static/images/selected.png">
          </div>
        </div>
        <div>
          <el-upload :multiple="fileType!=3" :action="isvuploadurl+'?type='+crntparam+'&pid='+path[path.length-1]['id']" list-type="picture-card" :name="crntparam" :data="uploadData" :auto-upload="true" :on-success="showRes" :show-file-list="false" :before-upload='checkObj'>
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </div>
      <el-dialog width="80%" :visible.sync="materialVisble" append-to-body>
        <img :src="crntMaterail" style="width:90%;margin-left:5%;margin-top:20px">
        <div slot="footer" class="dialog-footer">
          <el-button size="mini" type="primary" @click="materialVisble=false">关 闭</el-button>
        </div>
      </el-dialog>
      <el-dialog width="80%" :visible.sync="materialVideoVisble" append-to-body>
        <video :src="crntMaterail" style="width:90%;margin-left:5%;margin-top:20px" controls></video>
        <div slot="footer" class="dialog-footer">
          <el-button size="mini" type="primary" @click="materialVideoVisble=false">关 闭</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" type="primary" @click="materialDiv=false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable,
  },
  data() {
    return {
      ruleForm: {
        id: "",
        name: "",
        quotationType: 1,
        price: "",
        unit: "",
        classify: "",
        categoryId: "",
        hangye: '',
        hangyetype: 3,
        num: "", //信息发布数量
        daynum: "", //每天发布条数
        brand: "",
        area: "",
        district: [],
        status: 1,
        starttime: "",
        productType: "norm",
        website: '',
        useimage: 1,
        beizhu: '',
        interval: 0,
        detail: {
          keywords: "",
          keywords2: "",
          title: "",
          parameters: [],
          intro: [{ content: "" }],
          image: [],
          infoimg: [],
          video: [],
          firstimage: [],//轮播图首图
          tags: "",
          tags2: "",
          specdigest: [],
          specpricelist: [],
        }
      },
      specPrice: "",
      pricearr: [
        {
          startQuantity: "",
          price: "",
        },
        {
          startQuantity: "",
          price: "",
        },
        {
          startQuantity: "",
          price: "",
        },
      ],
      hangyes: [],
      unitlist: [],
      picHOST: window.domain,
      tempinfo: {},
      search_province: [], //百度爱采购接口获取地域信息【省】。
      search_city: [], //百度爱采购接口获取地域信息【市】。
      district: [],
      parameters: [],
      canshuming: "",
      canshuzhi: "",
      options: [],
      // options: areaData,
      checkAll: false,
      cities: [],
      freightlist: [],
      // cities: citys,
      isIndeterminate: false,
      parameterDialog: false,
      classifyDialog: false,
      classifyInnerVisible: false,
      searchResult: [],
      hangyes: [], //自有行业分类，用以选择对应修饰词数据表。
      uploadurl: this.HOST + "/datum/upload", //图片上传地址
      borderImageList: [], //带边框图片
      unBorderImageList: [], //不带边框图片
      imageInfoList: [], //详情图片
      videoList: [], //视频
      disabled: false,
      searchkey: "",
      activeName: "first",
      firstClassify: [], //一级行业分类
      secondClassify: [], //二级行业分类
      thirdClassify: [], //三级行业分类
      hasBorder: false, //行业是否有边框
      labelWidth: "150px",
      uploadData: {
        ziaccounttoken: sessionStorage.getItem("ziaccounttoken"),
        ziaccountuuid: sessionStorage.getItem("ziaccountuuid"),
      },
      // 图片、视频预览地址
      previewUrl: "",
      previewDialog: false,
      previewType: "image",
      pickerOptions: {
        shortcuts: [
          {
            text: "十分钟后 ",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 600000);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一小时后 ",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600000);
              picker.$emit("pick", date);
            },
          },
          {
            text: "明天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      loading: false,
      needConfirm: true,
      oldinfo: {},
      previewInfoImage: false,
      crntInfoImage: [],
      templateList: [],
      crntTemplate: '',
      props: {
        multiple: true
      },
      infoname: '',
      infoNameDiv: false,
      pathname: '',
      materialList: [],
      materialDiv: false,
      materialVisble: false,
      crntMaterail: '',
      path: [{ id: 0 }],
      crntparam: '',
      crnttitle: '',
      fileType: 1,
      pathNameDiv:false,
      materialloading: false,
      isvuploadurl: this.HOST + '/isvdatum/upload',//isv图片上传地址
      materialVideoVisble:false
    };
  },
  computed: {
    specPriceList() {
      let temp = [];
      for (let i = 0; i < this.ruleForm.detail.specdigest.length; i++) {
        if (this.ruleForm.detail.specdigest.name != '') {
          for (let ii = 0; ii < this.ruleForm.detail.specdigest[i].values.length; ii++) {
            if (this.ruleForm.detail.specdigest[i].values[ii].value != '') {
              temp.push({
                spec: this.ruleForm.detail.specdigest[i].name,
                value: this.ruleForm.detail.specdigest[i].values[ii].value,
                price: '',
                stock: 0,
                image: ''
              })
            }
          }
        }
      }
      for (let i = 0; i < temp.length; i++) {
        for (let ii = 0; ii < this.ruleForm.detail.specpricelist.length; ii++) {
          if (temp[i].spec == this.ruleForm.detail.specpricelist[ii].spec && temp[i].value == this.ruleForm.detail.specpricelist[ii].value) {
            temp[i].price = this.ruleForm.detail.specpricelist[ii].price;
            temp[i].stock = this.ruleForm.detail.specpricelist[ii].stock;
            temp[i].image = this.ruleForm.detail.specpricelist[ii].image ? this.ruleForm.detail.specpricelist[ii].image : '';
          }
        }
      }
      return temp;
    }
  },
  methods: {
    showloading() {
      this.loading = true;
    },
    setInfoImageData(index) {
      this.uploadInfoData.index = index;
    },
    //获取参数模板列表
    getTemplate() {
      this.$axios({
        url: this.HOST + "/datum/getTemplate",
        method: "post",
        data: {
          id: this.ruleForm.hangye
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.templateList = res.data.list
        }
      })
    },
    //使用参数模板
    useTemplate() {
      this.$axios({
        url: this.HOST + '/datum/getTemplateDetail',
        method: 'post',
        data: {
          id: this.crntTemplate
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.parameters.splice(10, this.parameters.length - 10);
          this.parameters = this.parameters.concat(res.data.param);
        }
      })
    },
    showhangye() {
      if (this.ruleForm.hangye == 0) {
        this.hasBorder = false;
        return;
      }
      for (let i in this.hangyes) {
        if (this.hangyes[i].id == this.ruleForm.hangye) {
          this.hasBorder = this.hangyes[i].border == 1 ? true : false;
          return;
        }
      }
    },
    //检查商品分类是否为三级
    checkCategory() {
      let patrn = /^(([1-9][0-9]{2})|([0-9]{2}[1-9])|([0-9][1-9][0-9])){3}$/;
      if (!patrn.exec(this.ruleForm.categoryId)) {
        this.$message({
          type: "error",
          message: "请选择三级分类",
        });
      }
    },
    //添加商品规格
    addSpec() {
      if (this.ruleForm.detail.specdigest.length >= 2) return;
      this.ruleForm.detail.specdigest.push({
        name: "",
        values: [
          {
            value: "",
          },
        ],
      });
    },
    //删除商品规格
    delSpecItem(index) {
      if (this.ruleForm.detail.specdigest.length == 1) return;
      this.ruleForm.detail.specdigest.splice(index, 1);
    },
    //删除商品规格值
    delSpecValue(index, x) {
      if (this.ruleForm.detail.specdigest[index].values.length == 1) return;
      this.ruleForm.detail.specdigest[index].values.splice(x, 1);
    },
    //检查商品规格值
    checkSpec(index, x) {
      if (this.ruleForm.detail.specdigest[index].values[x].value == "") {
        this.delSpecValue(index, x);
      } else {
        if (this.ruleForm.detail.specdigest[index].values.length < 20) {
          this.addSpecValue(index);
        }
      }
    },
    //添加商品规格值
    addSpecValue(index) {
      if (this.ruleForm.detail.specdigest[index].values.length >= 20) return;
      this.ruleForm.detail.specdigest[index].values.push({
        value: "",
      });
    },
    // 获取初始化数据，百度行业一级分类，自有行业分类。如果是修改，获取当前资料详情
    getInitData() {
      let id = this.$route.params.id;
      this.$axios({
        url: this.HOST + "/datum/getWeihuaInitData",
        method: "post",
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.firstClassify = res.data.firstClassify.data.categories;
          this.hangyes = res.data.hangyes;
          if (res.data.unitlist) {
            this.unitlist = res.data.unitlist;
          }
          let search_province = res.data.areas.data.search_province;
          let search_city = res.data.areas.data.search_city;
          // 将数据格式调整为el-cascader组件所需
          let areaData = [];
          // 将数据格式调整为el-checkbox组件所需
          let citys = [];
          for (let key in search_province) {
            let citysarr = [];
            for (let a in search_city[key]) {
              citysarr.push({
                value: search_city[key][a],
                label: search_city[key][a],
              });
            }
            areaData.push({
              label: search_province[key],
              value: search_province[key],
              children: citysarr,
            });
            citys.push(search_province[key]);
          }
          this.cities = citys;
          this.options = [{
            value: '全选',
            label: '全选',
            children: areaData
          }];
          if (res.data.datumInfo) {
            this.ruleForm = res.data.datumInfo;
            if (this.ruleForm.quotationType == 1) {
              this.pricearr = res.data.datumInfo.price;
            } else {
              this.specPrice = res.data.datumInfo.price;
            }
            this.ruleForm.price = "";
            this.parameters = res.data.datumInfo.detail.parameters;
          }
          this.unBorderImageList = res.data.unBorderImageList;
        } else {
          this.$router.push("/datum/new");
        }
      });
    },
    addInfoImage() {
      if (this.infoname == '') {
        this.$message({
          type: 'warning',
          message: '请输入详情图名称'
        })
        return;
      }
      this.ruleForm.detail.infoimg.push(
        {
          name: this.infoname,
          pic: []
        }
      );
      this.infoNameDiv = false;
    },
    delInfoImage(i) {
      this.ruleForm.detail.infoimg.splice(i, 1)
    },
    showInfoImage(pic) {
      this.crntInfoImage = pic;
      this.previewInfoImage = true;
    },    
    //图片上传结果提示
    showRes(res) {
      this.materialloading = false;
      if (res.status == 1) {
        this.materialList.push({
          fileType:res.fileType,
          fileName:res.name,
          url:res.url,
          fileExt:res.fileExt
        })
      } else {
        this.$message({
          type: "error",
          message: res.info,
        });
      }
    },
    showUpInfoImageRes(res) {
      this.loading = false;
      if (res.status == 1) {
        this.ruleForm.detail.infoimg[res.index].pic.push(res.file)
      }
    },
    // 选择分类按钮按钮
    classifyAllBtn() {
      // 初始化，搜索框清空，展示一级分类，二级、三级清空
      this.searchkey = "";
      this.activeName = "first";
      this.secondClassify = [];
      this.thirdClassify = [];
      this.classifyDialog = true;
    },
    // 选择内部分类按钮，获取分类。
    classifybtn(id, name, level) {
      if (level <= 2) {
        this.$axios({
          url: this.HOST + "/datum/getClassify",
          method: "post",
          data: {
            fatherId: id,
          },
        })
          .then((res) => {
            if (res.data.status == 1) {
              let classifyArr = res.data.list.data.categories;
              switch (level) {
                case 1:
                  this.secondClassify = classifyArr;
                  this.ruleForm.classify = name + ">";
                  this.activeName = "second";
                  break;
                case 2:
                  this.thirdClassify = classifyArr;
                  let firstname = this.ruleForm.classify.split(">")[0];
                  this.ruleForm.classify = firstname + ">" + name + ">";
                  this.activeName = "third";
                  break;
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.classifyDialog = false;
        let firstname = this.ruleForm.classify.split(">")[0];
        let secondname = this.ruleForm.classify.split(">")[1];
        this.ruleForm.classify = firstname + ">" + secondname + ">" + name;
        this.ruleForm.categoryId = id;
        this.$axios({
          url: this.HOST + "/datum/getUnit",
          method: "post",
          data: {
            id: id,
          },
        }).then((res) => {
          if (res.data.status == 1) {
            this.unitlist = res.data.list;
          }
        });
      }
    },
    // 通过关键词搜索百度分类
    getClassifyByKey() {
      if (!this.searchkey) {
        this.$message.error("请先输入关键词");
        return;
      }
      this.$axios({
        url: this.HOST + "/datum/getClassifyByKey",
        method: "post",
        data: {
          key: this.searchkey,
        },
      })
        .then((res) => {
          if (res.data.status == 1) {
            let results = res.data.list.data.categories;
            let resultsarr = [];
            for (let i = 0; i < results.length; i++) {
              resultsarr[i] = {
                cate_id: results[i]["cate_id"],
                name: results[i]["name"].replace(/;/g, ">"),
              };
            }
            this.searchResult = resultsarr;
            this.classifyInnerVisible = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 点击关键词查询结果
    searchResultBtn(id, name) {
      let patrn = /^(([1-9][0-9]{2})|([0-9]{2}[1-9])|([0-9][1-9][0-9])){3}$/;
      if (!patrn.exec(id)) {
        this.$message({
          type: "error",
          message: "请选择三级分类",
        });
        return;
      }
      this.ruleForm.classify = name;
      this.ruleForm.categoryId = id;
      this.classifyInnerVisible = false;
      this.classifyDialog = false;
      this.$axios({
        url: this.HOST + "/datum/getUnit",
        method: "post",
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.unitlist = res.data.list;
        }
      });
    },
    // 批量添加参数确定操作
    parametersBtn() {
      let canshumingarr = this.canshuming.split("\n");
      let canshuzhiarr = this.canshuzhi.split("\n");
      for (let i = 0; i < canshumingarr.length; i++) {
        this.parameters.push({
          name: canshumingarr[i],
          value: canshuzhiarr[i],
        });
      }
      this.parameterDialog = false;
    },
    // 全国选择
    handleCheckAllChange(val) {
      this.ruleForm.district = val ? this.cities : [];
      this.isIndeterminate = false;
    },
    // 省份选择
    handledistrictChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    // 上传图片
    // 图片或视频预览
    showPic(item, type) {
      if (type == "unborderimg") {
        this.previewUrl = this.picHOST + item;
      } else {
        this.previewUrl = item;
        if (type == "video") this.previewType = "video";
      }
      this.previewDialog = true;
    },
    delPic(file, fileList) {
      this.ruleForm.detail[fileList].splice(this.ruleForm.detail[fileList].indexOf(file), 1);
    },
    delInfoImagePic(index, i) {
      this.ruleForm.detail.infoimg[index].pic.splice(i, 1)
    },
    delSpecPic(scope) {
      scope.row.image = '';
    },
    setSpec(item){
      let index=sessionStorage.getItem('specindex');
      this.specPriceList[index].image = item.url;
      this.materialDiv = false;
    },
    // 清空图片、视频按钮
    clearUploadDir(typestr) {
      this.$confirm("确定清空所有文件吗?本操作不可恢复", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.ruleForm.detail[typestr] = [];
      });
    },
    // 新增文本框
    addTextarea() {
      this.ruleForm.detail.intro.push({
        content: "",
      });
    },
    // 移除文本
    removeTextarea(item) {
      var index = this.ruleForm.detail.intro.indexOf(item);
      if (index !== -1) {
        this.ruleForm.detail.intro.splice(index, 1);
      }
    },
    // 新增参数
    addParameter() {
      this.parameters.push({
        name: "",
        value: "",
      });
    },
    // 移除参数
    removeParameter(item) {
      var index = this.parameters.indexOf(item);
      if (index !== -1) {
        this.parameters.splice(index, 1);
      }
    },
    // 获取素材，type为素材类型：1文件夹；2图片；3视频；4pdf
    // pid为父目录id
    showMaterial(type, item) {
      item = arguments[1] ? arguments[1] : { id: 0 }
      this.materialloading = true;
      this.$axios({
        url: this.HOST + '/isvdatum/getMaterial',
        method: 'post',
        data: {
          fileType: type,
          parentId: item.id
        }
      }).then(res => {
        this.materialloading = false;
        if (res.data.status == 1) {
          if (item.id == 0) {
            this.path = [
              {
                id: 0,
                name: '根目录'
              }
            ]
          } else {
            let crnt = -1;
            for (let i in this.path) {
              if (this.path[i].id == item.id) {
                crnt = i;
                break;
              }
            }
            if (crnt == -1) {
              this.path.push({
                id: item.id,
                name: item.fileName
              })
            } else {
              this.path.splice(parseInt(crnt)+1)
            }
          }
          this.materialList = res.data.list;
          this.materialDiv = true;
        }
      })
    },
    selectBanner() {
      this.crntparam = 'image'
      this.crnttitle = '设置轮播图'
      this.fileType = 2;
      this.showMaterial(2);
      sessionStorage.setItem('type','image');
    },
    selectFirstImage() {
      this.crntparam = 'firstimage'
      this.crnttitle = '设置轮播首图'
      this.fileType = 2;
      this.showMaterial(2);
      sessionStorage.setItem('type','image');
    },
    selectVideo() {
      this.crntparam = 'video'
      this.crnttitle = '设置视频'
      this.fileType = 3;
      this.showMaterial(3);
      sessionStorage.setItem('type','video');
    },
    selectInfoImage() {
      this.crntparam = 'infoimg'
      this.crnttitle = '设置详情图'
      this.fileType = 2;
      this.showMaterial(2);
      sessionStorage.setItem('type','image');
    },
    selectSpecImage(index) {
      this.crntparam = 'spec'
      this.crnttitle = '选择规格图片'
      this.fileType = 2;
      this.showMaterial(2);
      sessionStorage.setItem('type','spec');
      sessionStorage.setItem('specindex',index)
    },
    changeSelect(item) {
      if (this.ruleForm.detail[this.crntparam].indexOf(item.url) == -1) {
        this.ruleForm.detail[this.crntparam].push(item.url)
      } else {
        this.ruleForm.detail[this.crntparam].splice(this.ruleForm.detail[this.crntparam].indexOf(item.url), 1)
      }
      console.log(this.crntparam, this.ruleForm.detail[this.crntparam])
    },
    showMaterailPic(url) {
      this.crntMaterail = url;
      this.materialVisble = true;
    },
    showMaterailVideo(url) {
      this.crntMaterail = url;
      this.materialVideoVisble = true;
    },
    checkObj(file) {
      let crnttype = sessionStorage.getItem('type');
      let type = file.type.split('/');
      type = type[0];
      let isType;
      if(crnttype=='video'){
        isType = type === 'video';
      }else{
        isType = type === 'image';
      }
      let isSize = file.size / 1024 / 1024 < 10;
      if (!isType) {
        this.$message.error('文件格式不正确!');
      }
      if (!isSize) {
        this.$message.error('文件大小不能超过10MB!');
      }
      this.materialloading = true;
      return isType && isSize;
    },
    addPath(){
      if(this.pathname == '') return;
      this.$axios({
        url:this.HOST+'/isvdatum/createDir',
        method:'post',
        data:{
          name:this.pathname,
          pid:this.path[this.path.length-1].id
        }
      }).then(res=>{
        if(res.data.status == 1){
          this.materialList.push({
            fileType:1,
            fileName:res.data.name,
            id:res.data.id
          })
          this.pathNameDiv = false;
        }
      })
    },
    // 表单提交
    submitForm(status) {
      for (let i in this.ruleForm) {
        this.tempinfo[i] = this.ruleForm[i];
      }
      this.tempinfo.detail.parameters = this.parameters;
      this.tempinfo.detail.specpricelist = this.specPriceList;
      this.tempinfo.status = status;
      // 阶梯价格处理。
      if (this.ruleForm.quotationType == "1") {
        this.tempinfo.price = this.pricearr;
      } else {
        this.tempinfo.price = this.specPrice;
      }
      this.loading = true;
      this.$axios({
        url: this.HOST + "/datum/submitForm",
        method: "post",
        data: this.tempinfo,
      })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 1) {
            if (status == 1) {
              this.$message({
                message: res.data.info,
                type: "success",
              });
              if (res.data.id) {
                this.ruleForm.id = res.data.id;
              }
            } else if (status == 4) {
              this.$message({
                message: res.data.info,
                type: "success",
                onClose: () => {
                  this.needConfirm = false;
                  this.$router.push("/datum/list");
                },
              });
            }
            if (res.data.id) {
              this.ruleForm.id = res.data.id;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    beforeunloadFn(e) {
      if (this.needConfirm) {
        let nc = false;
        for (let i in this.ruleForm) {
          if (this.oldinfo[i] != this.ruleForm[i]) {
            nc = true;
            break;
          }
        }
        if (!nc) {
          return;
        }
        e = e || window.event;
        if (e) {
          e.returnValue = "关闭提示";
        }
        return "关闭提示";
      }
    },
  },
  beforeRouteLeave: function (to, from, next) {
    if (this.needConfirm) {
      next(false);
      let nc = false;
      for (let i in this.ruleForm) {
        if (this.oldinfo[i] != this.ruleForm[i]) {
          nc = true;
          break;
        }
      }
      if (!nc) {
        next();
        return;
      }
      this.$confirm("确定要离开本页面吗？未保存的信息将会丢失", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      }).then(() => {
        next();
      });
      // .catch(() => {
      //   this.needSave = false;
      //   next();
      // });
    } else {
      next();
    }
  },
  created: function () {
    for (let i = 0; i < 10; i++) {
      this.parameters.push({
        name: "",
        value: "",
      });
    }
    this.getInitData();
    for (let i in this.ruleForm) {
      this.oldinfo[i] = this.ruleForm[i];
    }
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },

  destroyed() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadFn(e));
  },
};
</script>

