<template>
  <div>
    <div class="maintitle">{{$route.meta.title}}</div>
    <el-divider></el-divider>
    <el-form :model="templateInfo">
      <el-form-item label="边框图片" :label-width="formLabelWidth" class="text-left">
        <el-upload multiple :action="uploadurl" name="border" :data="uploadData" :auto-upload="true" :on-success="showPic" :show-file-list="false" :before-upload='showloading' style='display:inline-block'>
          <el-button type="primary" plain>
            <i class="el-icon-upload el-icon--rihgt"></i>点击选择图片
          </el-button>
        </el-upload>
        <div v-if="picwidth!=''" style='display:inline-block;margin-left:20px;'>宽：{{picwidth}}；高：{{picheight}}</div>
      </el-form-item>
      <el-form-item label="公司名称" :label-width="formLabelWidth">
        <el-input v-model="templateInfo.company" type="textarea" placeholder="请输入公司名称，每行一个公司名"></el-input>
      </el-form-item>
      <el-form-item label="位置(x)" :label-width="formLabelWidth">
        <el-input v-model="templateInfo.locationx" type="number"></el-input>
      </el-form-item>
      <el-form-item label="位置(y)" :label-width="formLabelWidth">
        <el-input v-model="templateInfo.locationy" type="number"></el-input>
      </el-form-item>
      <el-form-item label="布局" :label-width="formLabelWidth" class="text-left">
        <el-radio-group v-model="templateInfo.align" size="mini">
          <el-radio-button :label="1">靠左</el-radio-button>
          <el-radio-button :label="2">居中</el-radio-button>
          <el-radio-button :label="3">靠右</el-radio-button>
        </el-radio-group>
        <div style="font-size:10px;color:gray">靠左表示设置位置为文本的最左边；居中表示设置位置为文本的正中间；靠右表示设置位置为文本的最右边</div>
      </el-form-item>
      <el-form-item :label-width="formLabelWidth" label="排列方式" class="text-left">
        <el-radio-group v-model="templateInfo.direction">
          <el-radio-button label="vertical">竖排</el-radio-button>
          <el-radio-button label="horizontal">横排</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="字体" class="text-left" :label-width="formLabelWidth">
        <el-select v-model='templateInfo.font'>
          <el-option v-for="(item,index) in fontList" :key="index" :label="item.name+'('+item.auth+')'" :value="item.type"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="字号" :label-width="formLabelWidth">
        <el-input v-model="templateInfo.fontsize"></el-input>
      </el-form-item>
      <el-form-item label="字间距" :label-width="formLabelWidth">
        <el-input v-model="templateInfo.spacing" type="number"></el-input>
      </el-form-item>
      <el-form-item label="颜色" :label-width="formLabelWidth" class="text-left">
        <el-row style="margin-bottom:0">
          <el-col :span="4">
            <el-input v-model="templateInfo.color" disabled></el-input>
          </el-col>
          <span class="margin-left">
            <el-color-picker v-model="templateInfo.color"></el-color-picker>
          </span>
        </el-row>
      </el-form-item>
      <el-form-item :label-width="formLabelWidth" label="描边" class="text-left">
        <el-radio-group v-model="templateInfo.stroke">
          <el-radio-button :label="1">是</el-radio-button>
          <el-radio-button :label="0">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="描边颜色" :label-width="formLabelWidth" class="text-left" style="margin-bottom:0" v-if="templateInfo.stroke==1">
        <el-row style="margin-bottom:0">
          <el-col :span="4">
            <el-input v-model="templateInfo.strokecolor" disabled></el-input>
          </el-col>
          <span class="margin-left">
            <el-color-picker v-model="templateInfo.strokecolor"></el-color-picker>
          </span>
        </el-row>
      </el-form-item>
      <el-form-item label="描边大小" :label-width="formLabelWidth" v-if="templateInfo.stroke==1">
        <el-input v-model="templateInfo.strokewidth" type="number"></el-input>
      </el-form-item>
      <el-form-item :label-width="formLabelWidth" label="预览" class="text-left" v-if="picUrl != ''">
        <img :src="picUrl" alt="" style="width:250px;cursor:pointer" @click="showFullPic">
      </el-form-item>
    </el-form>
    <el-button @click="viewPic" type='primary'>预览</el-button>
    <el-button @click="subInfo" type='success'>生成</el-button>
    <el-dialog :visible.sync="previewDialog" width='100%'>
      <img :src="picUrl" style="width: 100%;" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      templateInfo: {
        company: '',
        locationx: 0,
        locationy: 50,
        align: 2,
        direction: 'horizontal',
        font: '',
        fontsize: 20,
        color: '',
        pic: '',
        spacing:6,
        stroke:0,
        strokecolor:'',
        strokewidth:''
      },
      uploadurl: this.HOST + '/picture/upSolarTermPic',
      picUrl: '',
      picwidth:"",
      picheight:'',
      fontList: [],
      picHOST: window.domain,
      uploadData: {
        ziaccounttoken: sessionStorage.getItem("ziaccounttoken"),
        ziaccountuuid: sessionStorage.getItem("ziaccountuuid"),
      },
      previewDialog:false,
    }
  },
  methods: {
    showloading() {
      this.loading = true;
    },
    showPic(res) {
      this.loading = false;
      this.templateInfo.pic = res.file
      this.picwidth = res.width;
      this.picheight = res.height;
      this.templateInfo.locationx = res.width/2;
    },
    viewPic() {
      if (this.templateInfo.pic == ''){
        this.$message({
          type:'error',
          message:'请先上传背景图'
        })
        return;
      };
      this.showloading();
      this.$axios({
        url: this.HOST + '/picture/viewPic',
        method: 'post',
        data: {
          info: this.templateInfo
        }
      }).then(res => {
        this.loading = false;
        if (res.data.status == 1) {

          this.picUrl = res.data.pic
        }
      })
    },
    showFullPic(){
      this.previewDialog = true;
    },
    subInfo(){
      this.loading = true;
      this.$axios({
        url:this.HOST+'/picture/createSolarTermPic',
        method:'post',
        data:{
          info:this.templateInfo
        }
      }).then(res=>{
        this.loading = false;
        if(res.data.status == 1){
          this.$message({
            type:'success',
            message:res.data.info
          })
          if(res.data.file){ console.log(this.picHOST+res.data.file)
            window.open(this.picHOST+res.data.file)
          }
        }
      })
    }
  },
  created() {
    this.$axios({
      url: this.HOST + '/picture/getFontList',
      method: "post",
      data: {}
    }).then(res => {
      if (res.data.status == 1) {
        this.fontList = res.data.list;
      }
    })
  }
}
</script>