<template>
  <div>
    <div class="maintitle">信息列表</div>
    <el-divider></el-divider>

    <el-row class="text-left">
      <el-col :span='12'>
        <el-tabs v-model="datumstatus" @tab-click="handleClick">
          <el-tab-pane v-for="(item,index) in datumstatuslist" :key='index' :label='item.title' :name='"s"+item.id'></el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span='12' class="text-right">
        <el-button type="success" size="mini" @click="addDatum">添加资料</el-button>
      </el-col>
    </el-row>
    <el-row class='text-left'>
      <el-col :span='8'>
        <el-input placeholder="请输入搜索关键词" size="mini" v-model='keyword'></el-input>
      </el-col>
      <el-button @click="getlist" class="margin-left" size="mini">搜索</el-button>
    </el-row>
    <el-table :data="list" border>
      <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
      <el-table-column prop="name" label="产品名称"></el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">
          <span style="display:inline-block">{{scope.row.beizhu}}</span>
          <el-button class="margin-left" @click="mdfyBeizhu(scope)" type="text">{{scope.row.beizhu==''?'添加备注':'修改备注'}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" sortable></el-table-column>
      <el-table-column prop="num" label="未发条数" v-if="crntstatus==2 || crntstatus==7" width="100" align="center"></el-table-column>
      <el-table-column label="状态" width="100" prop="status_text"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="mdfy(scope)" v-if="scope.row.status == 1">编辑</el-button>
          <el-button type="text" @click="submit(scope)" v-if="scope.row.status == 1">提交审核</el-button>
          <el-button type="text" @click="goodsList(scope)" v-if="scope.row.status == 2 || scope.row.status == 7">查看商品列表</el-button>
          <el-button type="text" @click="setStatus(scope)" v-if="scope.row.status == 2">暂停发布</el-button>
          <el-button type="text" @click="resume(scope)" v-if="scope.row.status == 7">继续发布</el-button>
          <el-button type="text" @click="mdfyLasttime(scope)" v-if="scope.row.status == 2">截止时间</el-button>
          <el-button type="text" @click="copy(scope)">复制</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background :current-page="page" :page-sizes="pagesizes" :page-size="pagesize" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    <el-dialog :visible.sync="mdfyDiv" :close-on-click-modal="false">
      <el-date-picker v-model="lasttime" type="datetime" placeholder="选择日期时间" :picker-options="pickerOptions">
      </el-date-picker>
      <div slot="footer" class="dialog-footer">
        <el-button @click="mdfyDiv = false">取 消</el-button>
        <el-button type="primary" @click="setLasttime">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="mdfyBeizhuDiv" :close-on-click-modal="false">
      <el-input v-model="newbeizhu" placeholder="请输入备注内容"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="mdfyBeizhuDiv = false">取 消</el-button>
        <el-button type="primary" @click="setBeizhu">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="setTimeDiv" :close-on-click-modal="false" title="设置开始发布时间">
      <el-date-picker v-model="newstarttime" type="datetime" placeholder="选择日期时间" :picker-options="pickerOptions">
      </el-date-picker>
      <div slot="footer" class="dialog-footer">
        <el-button @click="setTimeDiv = false">取 消</el-button>
        <el-button type="primary" @click="subResume">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //资料列表
      page: 1,
      total: 0,
      keyword: "",
      datumstatuslist: [],
      datumstatus: "s1",
      crntscope: '',
      newbeizhu: '',
      lasttime: '',
      mdfyDiv: false,
      mdfyBeizhuDiv: false,
      newstarttime: '',
      setTimeDiv: false,
      pickerOptions: {
        shortcuts: [
          {
            text: "十分钟后 ",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 600000);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一小时后 ",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600000);
              picker.$emit("pick", date);
            },
          },
          {
            text: "明天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  computed: {
    crntstatus() {
      return this.datumstatus.replace("s", "");
    },
  },
  methods: {
    // 获取资料列表，注意通过商户id获取对应资料，该设置尚未处理。
    getlist() {
      this.$axios({
        url: this.HOST + "/datum/list",
        method: "post",
        data: {
          page: this.page,
          pagesize: this.pagesize,
          keyword: this.keyword,
          datumstatus: this.crntstatus,
        },
      }).then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.list.data;
          this.total = res.data.list.total;
          this.datumstatuslist = res.data.datumstatus;
        }
      });
    },
    //弹出恢复发布时间选择层
    resume(scope) {
      this.crntscope = scope;
      this.newstarttime = this.getDate('now');
      this.setTimeDiv = true;
    },
    //提交恢复发布信息
    subResume() {
      this.$axios({
        url: this.HOST + "/datum/setStatus",
        method: "post",
        data: {
          id: this.crntscope.row.id,
          status: 2,
          time: this.newstarttime
        },
      })
        .then((res) => {
          if (res.data.status == 1) {
            this.$message.success(res.data.info);
            this.setTimeDiv = false;
            this.getlist();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //修改备注
    mdfyBeizhu(scope) {
      this.crntscope = scope;
      this.newbeizhu = scope.row.beizhu;
      this.mdfyBeizhuDiv = true;
    },
    // 添加资料
    addDatum() {
      if (sessionStorage.getItem('third_party') == 1) {
        this.$router.push("/datum/isvnew");
      } else {
        this.$router.push("/datum/new");
      }
    },
    // 提交审核
    submit(data) {
      this.$confirm("确定提交审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: this.HOST + "/datum/saveStatus",
            method: "post",
            data: {
              aid: 3,
              id: data.row.id,
              status: 4,
            },
          })
            .then((res) => {
              if (res.data.status == 1) {
                this.$message.success(res.data.info);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    handleClick(tab, event) {
      this.page = 1;
      this.getlist();
    },
    // 按状态查询
    srch() {
      this.page = 1;
      this.getlist();
    },
    // 暂停发布
    setStatus(scope) {
      let mess = "";
      let status = scope.row.status;
      if (status == 2) {
        mess = "确定暂停发布吗?";
        status = 7;
      } else {
        mess = "确定开始发布吗?";
        status = 2;
      }
      this.$confirm(mess, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios({
            url: this.HOST + "/datum/setStatus",
            method: "post",
            data: {
              id: scope.row.id,
              status: status,
            },
          })
            .then((res) => {
              if (res.data.status == 1) {
                this.$message.success(res.data.info);
              } else {
                this.$message.error(res.data.info);
              }
              this.getlist();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          console.log("fuck");
          this.$message({
            type: "info",
            message: "已取消操作11111",
          });
        });
    },
    setBeizhu() {
      this.$axios({
        url: this.HOST + '/datum/setBeizhu',
        method: 'post',
        data: {
          id: this.crntscope.row.id,
          beizhu: this.newbeizhu
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.$message({
            type: 'success',
            message: res.data.info
          })
          this.crntscope.row.beizhu = this.newbeizhu;
          this.mdfyBeizhuDiv = false
        }
      })
    },
    // 修改资料
    mdfy(scope) {
      switch (scope.row.hangyetype) {
        case 2: //花卉行业
          if (sessionStorage.getItem('third_party') == 1) {
            this.$router.push("/datum/isvhuahui/" + scope.row.id)
          } else {
            this.$router.push("/datum/huahui/" + scope.row.id);
          }
          break;
        case 3: //危化品行业
          if (sessionStorage.getItem('third_party') == 1) {
            this.$router.push("/datum/isvweihua/" + scope.row.id)
          } else {
            this.$router.push("/datum/weihua/" + scope.row.id);
          }
          break;
        default:
          if (sessionStorage.getItem('third_party') == 1) {
            this.$router.push("/datum/isvnew/" + scope.row.id)
          } else {
            this.$router.push("/datum/new/" + scope.row.id);
          }
      }
      // this.$router.push("/datum/new/" + scope.row.id);
    },
    // 查看商品列表
    goodsList(scope) {
      this.$router.push("/datum/goodslist/" + scope.row.id);
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    copy(scope) {
      this.$confirm(
        "确定复制信息吗?本操作不可恢复，而且会占用信息总条数",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.loading = true;
          this.$axios({
            url: this.HOST + "/datum/copy",
            method: "post",
            data: {
              id: scope.row.id,
            },
          })
            .then((res) => {
              this.loading = false;
              if (res.data.status == 1) {
                this.getlist();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    mdfyLasttime(scope) {
      this.crntscope = scope;
      this.lasttime = scope.row.lasttime;
      this.mdfyDiv = true;
    },
    setLasttime() {
      this.$axios({
        url: this.HOST + '/datum/setLasttime',
        method: 'post',
        data: {
          id: this.crntscope.row.id,
          lasttime: this.lasttime
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.$message({
            type: 'success',
            message: res.data.info
          })
          this.crntscope.row.lasttime = this.lasttime;
          this.mdfyDiv = false;
        }
      })
    },
  },
  created: function () {
    let id = this.$route.params.status;
    if (id) {
      this.datumstatus = id;
    }
    this.getlist();
  },
};
</script>

<style scoped>
i {
  cursor: pointer;
}
</style>
